<template>
  <div class="entities" v-if="canView">
    <page-header title="Familles à archiver" icon="fa fa-users-slash" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="warning-text" v-if="!isLoading(loadingName)">
      Conformément aux RGPD, les données personnelles des familles qui ne fréquentent plus la structure
      sur les années actives doivent être supprimées.<br /><br />
      Cet outil vous permet d'anonymiser les données de ces familles et de conserver seulement.
      <ul>
        <li>Les inscriptions et ventes à des fins de statistiques</li>
        <li>Les données des membres qui sont aussi membres d'une autre famille active</li>
        <li v-if="tags.length">
          Les données des membres qui sont aussi membres d'une liste cochée ci-dessous, par exemple,
          si vous avez besoin de conserver leurs coordonnées. Une nouvelle famille sera ainsi créé automatiquement
          pour les membres conservés.
        </li>
      </ul>
      <u v-if="canChange">Attention les autres données seront perdues!</u>
      <u v-else>Vous n'êtes pas autorisé à effectuer l'archivage des familles</u>
    </div>
    <div v-if="tags.length && !isLoading(loadingName)" class="keep-tags">
      <b>Conserver les membres des listes: </b>
      <check-box-select
        :choices="tags"
        @changed="onTagsChanged($event)"
        id="tags"
        inline
      ></check-box-select>
    </div>
    <x-table
      :items="items"
      :columns="columns"
      :show-counter="showCounter"
      verboseName="famille à anonymiser"
      verboseNamePlural="familles à anonymiser"
      @selectionChanged="onSelectionChanged($event)"
      :keep-selection-on-filter="true"
      v-if="!isLoading(loadingName)"
    >
    </x-table>
    <b-modal
      dialog-class="modal-lg"
      id="anonymize-modal"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Oui"
      :cancel-disabled="isLoading(loadingName)"
      :ok-disabled="formInvalid || isLoading(loadingName)"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-eye-slash"></i> Anonymiser</b>
      </template>
      <b-row>
        <b-col>
          Êtes vous sûr de vouloir anonymiser
          <span v-if="selectedItems.length === 1">la famille sélectionnée</span>
          <span v-else>les {{ selectedItems.length }} familles sélectionnées</span>
        </b-col>
      </b-row>
      <b-row v-if="keepTags.length && keepEntitiesMap.size">
        <b-col>
          <br />
          Les membres
          <b v-if="keepTags.length === 1">de la liste {{ keepTagsNames }}</b>
          <b v-else>des listes {{ keepTagsNames }}</b>
           seront conservés.
        </b-col>
      </b-row>
      <loading-gif :loading-name="loadingName" message="Veuillez patienter"></loading-gif>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeEntity, makeIndividual, makeTag } from '@/types/people'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import XTable from '@/components/Controls/Table/Table'
import router from '@/router'
import { dateToString } from '@/filters/texts'
import { makeSchoolYear } from '@/types/schools'

export default {
  name: 'InactiveFamilies',
  mixins: [BackendMixin],
  components: {
    CheckBoxSelect,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'archive-entities',
      entities: [],
      columns: [],
      showCounter: true,
      selectedItems: [],
      keepTags: [],
      tags: [],
      keepEntitiesMap: new Map(),
    }
  },
  computed: {
    formInvalid() {
      return this.selectedItems.length === 0
    },
    items() {
      return this.entities.map(
        elt => this.makeItem(elt)
      )
    },
    canView() {
      return this.hasPerm('families.view_familyentity')
    },
    canChange() {
      return this.hasAllPerms(['families.change_familyentity', 'home.change_structure'])
    },
    keepTagsNames() {
      return this.keepTags.map(elt => elt.name).join(', ')
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    showModal() {
      this.$bvModal.show('anonymize-modal')
    },
    getLinks() {
      const links = []
      if (this.canChange) {
        links.push(
          {
            id: 1,
            label: 'Anonymiser',
            callback: this.showModal,
            icon: 'fa fa-eye-slash',
            cssClass: (
              (this.isLoading(this.loadingName) || this.selectedItems.length === 0)
                ? 'btn-primary disabled' : 'btn-primary'
            ),
          }
        )
      }
      return links
    },
    onSelectionChanged(event) {
      this.selectedItems = event.items.map(elt => elt.id)
      const columns = this.getColumns()
      if (columns.length !== this.columns.length) {
        this.columns = columns
      }
      this.loadKeepIndividuals()
    },
    onTagsChanged(event) {
      this.keepTags = event.choices
      const columns = this.getColumns()
      if (columns.length !== this.columns.length) {
        this.columns = columns
      }
      this.loadKeepIndividuals()
    },
    async onSave() {
      let url = '/api/families/archive-families/'
      const data = {
        entities: this.selectedItems,
        keep_tags: this.keepTags.map(elt => elt.id),
      }
      const backendApi = new BackendApi('post', url)
      this.startLoading(this.loadingName)
      try {
        const resp = await backendApi.callApi(data)
        const count = resp.data['families_count']
        let label = '' + count + ' famille'
        if (count > 1) {
          label += 's'
        }
        this.$bvModal.hide('anonymize-modal')
        await this.addSuccess('L\'anonymisation de ' + label + ' a été effectuée')
      } catch (err) {
        this.$bvModal.hide('anonymize-modal')
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      await this.loadInactiveFamilies()
    },
    getColumns() {
      const columns = [
        { selector: true, 'name': 'selector', maxWidth: '20px', },
        {
          name: 'name',
          label: 'Famille',
          maxWidth: '100px',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.id)
          },
          linkUrl: item => {
            return router.resolve({ name: 'families-detail', params: { entityId: item.id, }, }).href
          },
        },
        {
          name: 'memberships',
          label: 'Membres',
          maxWidth: '150px',
        },
        {
          name: 'cityName',
          label: 'Ville',
          maxWidth: '100px',
        },
        {
          name: 'createdOn',
          label: 'Créé le',
          maxWidth: '100px',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'lastActivityYear',
          label: 'Dernière activité',
          maxWidth: '100px',
        }
      ]
      if (this.keepTags.length && this.selectedItems.length) {
        columns.push(
          {
            name: 'keepIndividuals',
            label: 'Conserve',
            maxWidth: '100px',
          }
        )
      }
      return columns
    },
    makeItem(elt) {
      return {
        id: elt.entity.id,
        entity: elt.entity,
        name: elt.entity.name,
        createdOn: dateToString(elt.entity.createdOn, 'YYYY-MM-DD'),
        memberships: elt.entity.members,
        cityName: elt.entity.city.name,
        lastActivityYear: elt.lastSchoolYear.getName(),
        keepIndividuals: this.keepEntitiesMap.get(elt.entity.id) || '',
      }
    },
    onLoaded() {
      this.loadInactiveFamilies()
      this.loadTags()
      this.columns = this.getColumns()
    },
    async loadKeepIndividuals() {
      const keepEntitiesMap = new Map()
      if (this.selectedItems.length && this.tags.length) {
        const keepIndividualsMap = new Map()
        let url = '/api/families/anonymize-keep-tags/'
        const data = {
          entities: this.selectedItems,
          keep_tags: this.keepTags.map(elt => elt.id),
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          for (const elt of resp.data) {
            const individual = makeIndividual(elt.individual)
            const tag = makeTag(elt.tag)
            let individualTags = []
            if (keepIndividualsMap.has(individual.id)) {
              individualTags = keepIndividualsMap.get(individual.id)
            }
            individualTags = [individual, tag]
            keepIndividualsMap.set(individual.id, individualTags)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        for (const elt of this.entities) {
          const lines = []
          const entity = elt.entity
          if (this.selectedItems.indexOf(entity.id) >= 0) {
            for (const individualId of entity.memberIds) {
              if (keepIndividualsMap.has(individualId)) {
                const arr = keepIndividualsMap.get(individualId)
                const value = arr[0].firstAndLastName()
                if (lines.indexOf(value) < 0) {
                  lines.push(value)
                }
              }
            }
            if (lines.length) {
              keepEntitiesMap.set(entity.id, lines.join(', '))
            }
          }
        }
      }
      this.keepEntitiesMap = keepEntitiesMap
    },
    async loadInactiveFamilies() {
      this.startLoading(this.loadingName)
      this.entities = []
      this.selectedItems = []
      let url = '/api/families/archive-families/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.entities = resp.data.map(
          elt => {
            return {
              entity: makeEntity(elt.entity),
              lastSchoolYear: makeSchoolYear(
                { id: elt['last_year'], start_year: elt['last_year'], }
              ),
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadTags() {
      this.tags = []
      this.keepTags = []
      let url = '/api/people/tags/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.tags = resp.data.map(elt => makeTag(elt))
      } catch (err) {
      }
    },
  },
}
</script>

<style lang="less">
.keep-tags {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: solid 1px #ccc;
  background: #fdffb6;
  font-weight: bold;
}
</style>
